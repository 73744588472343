
import { defineComponent, reactive, ref, computed } from '@vue/composition-api'
import { camelCase as toCamelCase } from 'lodash-es'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { useNotify } from '@/store'

import { isRequired } from '@/utils/validation'
import { handleError } from '@/utils/handleError'
import { isOfType } from '@/utils/types/isOfType'
import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'

import { useCreateEnumItem } from '@/api/enumItem'
import { ENUMITEM_ASSIGNMENTS, EnumItemInput, EnumItemOutput } from '@/api/types/enumItem'

export default defineComponent({
  name: 'AddEditEnumItemDialog',
  components: {
    CommonAddEditDialog,
    CommonAutocomplete,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup: (_, { root, emit }) => {
    const { addNotification } = useNotify()

    const ASSIGNMENTS = [
      {
        value: ENUMITEM_ASSIGNMENTS.PSP_ACCOUNT_TAG,
        text: root.$t(
          `baseData.enumItems.form.enumItemAssignment.${toCamelCase(ENUMITEM_ASSIGNMENTS.PSP_ACCOUNT_TAG)}`
        ),
      },
      {
        value: ENUMITEM_ASSIGNMENTS.APPLICATION_STATUS,
        text: root.$t(
          `baseData.enumItems.form.enumItemAssignment.${toCamelCase(ENUMITEM_ASSIGNMENTS.APPLICATION_STATUS)}`
        ),
      },
      {
        value: ENUMITEM_ASSIGNMENTS.TENDERING_STATUS,
        text: root.$t(
          `baseData.enumItems.form.enumItemAssignment.${toCamelCase(ENUMITEM_ASSIGNMENTS.TENDERING_STATUS)}`
        ),
      },
      {
        value: ENUMITEM_ASSIGNMENTS.CONTRACT_END_CATEGORY,
        text: root.$t(
          `baseData.enumItems.form.enumItemAssignment.${toCamelCase(ENUMITEM_ASSIGNMENTS.CONTRACT_END_CATEGORY)}`
        ),
      },
      {
        value: ENUMITEM_ASSIGNMENTS.PROFESSIONAL_UNIT_CATEGORY,
        text: root.$t(
          `baseData.enumItems.form.enumItemAssignment.${toCamelCase(ENUMITEM_ASSIGNMENTS.PROFESSIONAL_UNIT_CATEGORY)}`
        ),
      },
      {
        value: ENUMITEM_ASSIGNMENTS.DEMAND_TYPE,
        text: root.$t(`baseData.enumItems.form.enumItemAssignment.${toCamelCase(ENUMITEM_ASSIGNMENTS.DEMAND_TYPE)}`),
      },
      {
        value: ENUMITEM_ASSIGNMENTS.CAPACITY_TEAM_STATUS,
        text: root.$t(
          `baseData.enumItems.form.enumItemAssignment.${toCamelCase(ENUMITEM_ASSIGNMENTS.CAPACITY_TEAM_STATUS)}`
        ),
      },
      {
        value: ENUMITEM_ASSIGNMENTS.CONTRACT_STATUS,
        text: root.$t(
          `baseData.enumItems.form.enumItemAssignment.${toCamelCase(ENUMITEM_ASSIGNMENTS.CONTRACT_STATUS)}`
        ),
      },
      {
        value: ENUMITEM_ASSIGNMENTS.CONTRACT_TYPE,
        text: root.$t(
          `baseData.enumItems.form.enumItemAssignment.${toCamelCase(ENUMITEM_ASSIGNMENTS.CONTRACT_TYPE)}`
        ),
      },
      {
        value: ENUMITEM_ASSIGNMENTS.PERSON_SPECIAL_ROLE,
        text: root.$t(
          `baseData.enumItems.form.enumItemAssignment.${toCamelCase(ENUMITEM_ASSIGNMENTS.PERSON_SPECIAL_ROLE)}`
        ),
      },
    ]

    const FORM_FIELDS: FormField[] = [
      {
        value: 'enumItemAssignment',
        fieldType: FORM_FIELDS_ENUM.DROPDOWN,
        items: computed(() => ASSIGNMENTS),
        isRequired: true,
        rules: [
          (value: string) => isRequired(value, root.$t('baseData.enumItems.form.enumItemAssignment.title') as string),
        ],
      },
      {
        value: 'name',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('baseData.enumItems.form.name') as string)],
      },
    ]

    const form = ref<EnumItemInput | EnumItemOutput>({} as EnumItemInput)

    function close(): void {
      emit('close')
    }

    const { createEnumItem: createEnumItemXhr, isLoading: isLoadingCreateEnumItem } = useCreateEnumItem()

    async function createEnumItem(): Promise<void> {
      if (!isOfType<EnumItemOutput>(form.value, 'id')) {
        try {
          await createEnumItemXhr(form.value)

          addNotification({
            text: root.$t(`baseData.enumItems.create.message`) as string,
            type: 'success',
          })

          close()

          emit('refetch-enumItems')
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    return reactive({
      constants: {
        FORM_FIELDS,
        FORM_FIELDS_ENUM,
      },
      state: {
        form,

        isLoadingCreateEnumItem,
      },
      listeners: {
        close,

        createEnumItem,
      },
    })
  },
})
