import UseAxios from '@/composables/types/useAxios'
import { UseAxiosPaginated } from '@/composables/types/useAxiosPaginated'
import { Hateoas } from './hateoas'
import { BasicEntity, MetaProperties } from './misc'

enum ENUMITEM_ASSIGNMENTS {
  PSP_ACCOUNT_TAG = 'PSP_ACCOUNT_TAG',
  APPLICATION_STATUS = 'APPLICATION_STATUS',
  TENDERING_STATUS = 'TENDERING_STATUS',
  CONTRACT_END_CATEGORY = 'CONTRACT_END_CATEGORY',
  PROFESSIONAL_UNIT_CATEGORY = 'PROFESSIONAL_UNIT_CATEGORY',
  DEMAND_TYPE = 'DEMAND_TYPE',
  CAPACITY_TEAM_STATUS = 'CAPACITY_TEAM_STATUS',
  CONTRACT_STATUS = 'CONTRACT_STATUS',
  CONTRACT_TYPE = 'CONTRACT_TYPE',
  PERSON_SPECIAL_ROLE = 'PERSON_SPECIAL_ROLE',
}

interface EnumItemOutput extends MetaProperties, Hateoas {
  enumItemAssignment: string
  name: string
  protected: boolean
}

type EnumItemId = EnumItemOutput['id']

type EnumItemInput = Omit<EnumItemOutput, keyof MetaProperties | keyof Hateoas>

type UseGetEnumItems = UseAxiosPaginated<EnumItemOutput>
type UseGetEnumItemsBasic = UseAxios<BasicEntity[]>
type UseCreateEnumItem = { createEnumItem: (data: EnumItemInput) => Promise<EnumItemOutput> } & UseAxios<EnumItemOutput>
type UseDeleteEnumItem = {
  deleteEnumItem: (id: EnumItemId) => Promise<void>
} & UseAxios<void>

export {
  ENUMITEM_ASSIGNMENTS,
  EnumItemOutput,
  EnumItemId,
  EnumItemInput,
  UseGetEnumItems,
  UseGetEnumItemsBasic,
  UseCreateEnumItem,
  UseDeleteEnumItem,
}
